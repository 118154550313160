import React from 'react'
import { Link } from 'gatsby'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { LinkArrowIcon, Stones, StoneStyles } from 'components/icons'
import { Nav } from 'components/nav'
import { TechSection } from 'components/icons/Technologies'
import { AI, BigData, Blockchain, DevOps, Distributed, FullStack, Mobile, Privacy, VR } from '../components/icons/Technologies'
import { BackgroundWithGrid } from '../components/layout'
import { LdJsonLogo, HeaderLink } from '../components/semanticdata'
import Analytics from '../components/analytics'

const ServicesPage = () => (
  <Layout className="bg-primary-2 text-primary-3">
    <Analytics />
    <SEO title="Services" pageUrl="/services/" description="We bring optimal business value and product growth. We have got you covered from MVP to tech ownership." />
    <LdJsonLogo />
    <MainWithMenu className="grid">
      <Header className="z-20 pt-6 mb-72 sm:h-48">
        <HeaderLink className="text-primary-2" />
      </Header>
      <BackgroundWithGrid image="services-hero" gridRow={1} />
      <Deco decoType={2} inner after gridRow={1} />
      <Section gridRow={2}>
        <h1 className="text-left text-primary-1 text-4xl lg:text-5xl">Services</h1>
      </Section>
      <Section className="z-20 pt-12 pb-48 grid gap-12 grid-cols-6 lg:grid-cols-12" gridRow={3}>
        <p className="col-span-6 text-xl lg:text-2xl">We deliver top-notch software solutions. We bring optimal business value and product growth. We have got you covered from MVP to tech ownership.</p>
        <div className="col-span-5">
          <p>
            "Tumba" is a <a href="https://en.wikipedia.org/wiki/Tumba_Peak_(Belasica)">mountain peak</a> in Bulgaria. Its meaning in Bulgaria describes a group of friends with common goals very close and strong together. We have more than 20 years experience
            in diverse technologies, different business stages and industry domains.
          </p>
        </div>
        <div className="col-span-6">
          <h2 className="col-span-5 text-2xl lg:text-3xl">Technologies</h2>
          <div className="mt-10 grid gap-x-4 gap-y-8 grid-cols-3">
            <TechSection Icon={Mobile} rotate={0} text="Mobile" />
            <TechSection Icon={FullStack} rotate={-75} text="Full Stack" />
            <TechSection Icon={BigData} rotate={90} text="Big Data" />
            <TechSection Icon={AI} rotate={0} text="Machine Learning" />
            <TechSection Icon={Blockchain} rotate={-75} text="Blockchain" />
            <TechSection Icon={VR} rotate={90} text="AR &amp; VR" />
            <TechSection Icon={Distributed} rotate={0} text="Distributed Systems" />
            <TechSection Icon={Privacy} rotate={-75} text="Privacy &amp; Security" />
            <TechSection Icon={DevOps} rotate={90} text="DevOps" />
          </div>
        </div>
        <div className="col-span-5">
          <h2 className="text-2xl lg:text-3xl">Process</h2>
          <p className="py-6 pt-10">
            At Tumba we keep it simple - we adjust to your agile work process, provide predictable cost and ensure sustainable and unmatched product growth. Tumba team can become an integral part of your business or a SWAT team for your MVP/ idea validation.
          </p>
          <p>
            We keep our teams small and focused, composed of senior software generalists with the right mix of roles and responsibilities to get the job done right. This structure ensures continuity and deep knowledge retention. Our risk-free Starter
            Accelerator program helps us both determine if we're a good fit for a long-term, successful partnership.
          </p>
        </div>
        <div className="col-span-5 lg:col-span-6">
          <h2 id="ownership" className="text-2xl lg:text-3xl">
            Tech Ownership
          </h2>
          <p className="pt-10">You are the business owner. We will be the responsible party for technical growth strategy, alignment between units, and value added.</p>
        </div>
        <div className="col-span-5">
          <h2 id="growth" className="text-2xl lg:text-3xl">
            Product Growth
          </h2>
          <p className="pt-10">You have built a viable product with growth potential. We will help you navigate to its most sustainable, productive and commercial future.</p>
        </div>
        <div className="col-span-5 lg:col-span-6">
          <h2 id="extension" className="text-2xl lg:text-3xl">
            Team Extensions
          </h2>
          <p className="pt-10">Your team needs expertise measured in productivity, not in #devs per hour. We will be an integral part of your team, enhance the performance and satiate the valid business needs.</p>
        </div>
        <div className="col-span-5">
          <h2 id="poc" className="text-2xl lg:text-3xl">
            Proof of Concept
          </h2>
          <p className="pt-10">You have a solid business idea. You know the tech behind is crucial and you know you need a partner. We will bring clarity and efficiency. We will make sure you see your idea validated.</p>
        </div>
      </Section>
      <Deco before after className="bg-primary-1" gridRow={4} />
      <Section className="z-20 pt-12 grid gap-12 grid-cols-3 text-primary-2" gridRow={4}>
        <h2 id="success-stories" className="col-span-3 text-2xl lg:text-3xl">
          Success stories
        </h2>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">I would happily recommend Tumba Solutions to any organization looking to partner with a mobile engineering group that can truly become part of their team.</p>
          <p className="mt-8">Michael, VP Engineering</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">You became a clear extension to our team, shared true passion, always finding better ways to achieve our goals!</p>
          <p className="mt-8">Richard, Commercial Director</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">A true partnership! Tumba are very easy to work with, always reliable, trustworthy, thorough and proactive. They care not only about the client, but also the customer's experience.</p>
          <p className="mt-8">Michael, VP Product</p>
        </div>
      </Section>
      <Section className="z-20 pt-40 pb-24 lg:pb-40 grid gap-12 grid-cols-6 lg:grid-cols-11" gridRow={5}>
        <div className="col-span-6 lg:col-span-11">
          <Stones className="h-20 overflow-visible" stoneClasses="transform origin-center box-fill" styles={StoneStyles[1]} />
          <p className="pt-6 text-2xl lg:text-3xl">Explore</p>
        </div>
        <Link to="/industries/" className="pt-8 col-span-4 flex items-baseline text-primary-1">
          <span className="text-lg">Industries</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
        <Link to="/expertise/" className="pt-8 col-span-4 flex items-baseline text-primary-1">
          <span className="text-lg">Expertise</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
        <Link to="/team/" className="pt-8 col-span-3 flex items-baseline text-primary-1">
          <span className="text-lg">Team</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
      </Section>
      <Deco before decoType={1} gridRow={6} className="bg-primary-1" />
      <Nav className="z-20 text-primary-2" gridRow={7} />
    </MainWithMenu>
  </Layout>
)

export default ServicesPage
